import React, {useState} from "react";
import {AlertMessage} from "./AlertMessage";

export const DigitCode = (props) => {

    const {visible, setVisible, callback, errorCallback} = props;

    const [digits, setDigits] = useState([]);
    const [alert, setAlert] = useState(null);
    const [_, refreshState] = useState();

    const handleSuccess = (message) => setAlert({message: message, status: 'success', date: new Date()});
    const handleError = (message) => setAlert({message: message, status: 'error', date: new Date()});

    const makeRequest = async () => {
        const token = localStorage.getItem('token');

        setDigits([]);
        const pin = digits.join('');

        let response = await fetch(process.env.REACT_APP_API_ADDRESS + 'users?' + new URLSearchParams({
            digitCode: pin
        }), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

        if(response.ok){
            response = await response.json();

            if(response.length === 0)
                handleError(`No user with PIN #${pin} found.`);

            else if(response.length > 1)
                handleError('Two or more users have the same PIN code.');

            else if(response.length === 1){
                let user = response[0];

                handleSuccess('Identified as ' + user.firstname + ' ' + user.lastname);

                callback(user);
                setVisible(false);
                setDigits([]);
            }
        }
        else
            handleError('Something went wrong.');
    };

    const handleClick = (value) => {
        let tmpDigits = digits;
        tmpDigits.push(value);

        if(tmpDigits.length >= 4){
            makeRequest();
            tmpDigits = [];
        }

        setDigits(tmpDigits);
        refreshState({});
    };

    const circles = () => {
        let circles = [];
        for(let i=0; i<4; i++){
            if(digits.length > i)
                circles.push(<span key={i} className="digit__progress filled"/>)
            else
                circles.push(<span key={i} className="digit__progress"/>)
        }

        return circles;
    }

    const displayClass = visible ? 'visible' : '';

    const handleClickOut = () => {
        setVisible(false);
        setDigits([]);
    };

    return (
        <>
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}

            <div id="digit-code-container" className={`right-part__container ${displayClass}`}
                onClick={handleClickOut}>

                <div id="digit-code-form" onClick={(e) => e.stopPropagation()}>
                    <p>Enter your PIN</p>
                    <div>
                        <div className="digit__number" onClick={() => handleClick(1)}>1</div>
                        <div className="digit__number" onClick={() => handleClick(2)}>2</div>
                        <div className="digit__number" onClick={() => handleClick(3)}>3</div>
                    </div>
                    <div>
                        <div className="digit__number" onClick={() => handleClick(4)}>4</div>
                        <div className="digit__number" onClick={() => handleClick(5)}>5</div>
                        <div className="digit__number" onClick={() => handleClick(6)}>6</div>
                    </div>
                    <div>
                        <div className="digit__number" onClick={() => handleClick(7)}>7</div>
                        <div className="digit__number" onClick={() => handleClick(8)}>8</div>
                        <div className="digit__number" onClick={() => handleClick(9)}>9</div>
                    </div>
                    <div>
                        <div className="digit__number" onClick={() => handleClick(0)}>0</div>
                    </div>
                    <p>
                        {
                            circles()
                        }
                    </p>
                </div>
            </div>
        </>
    );

}
