export const Tag = (props) => {

    const {content} = props;

    const stringToReadableColor = str => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Generate RGB values with a limited range to avoid extreme colors
        let r = (hash & 0x7F) + 100; // Between 100-227
        let g = ((hash >> 8) & 0x7F) + 100; // Between 100-227
        let b = ((hash >> 16) & 0x7F) + 100; // Between 100-227

        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };

    return (
        <span className="tag" style={{backgroundColor: stringToReadableColor(content)}}>
            {content}
        </span>
    );
};