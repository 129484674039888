import React, {useEffect, useRef, useState} from "react";
import {SimpleDropZoneOneFile} from "../common/SimpleDropZone";
import {postTaskFastPrint} from "../../api/apiGantt";
import {CardSlicerBuildTable} from "../pic/cards/CardSlicerBuildTable";
import {useGetData} from "../../api/useFetch";
import {getCurrentUser} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {DigitCode} from "../common/DigitCode";
import {getGeneralDownloadUrl, getPieceDownloadUrl, postStartPrint} from "../../api/apiConnectivity";
import {getStartPrintErrorModalDataByErrorCode} from "../../services/automation/ConnectivityService";
import {ControllablePrinterModal} from "./ControllablePrinterModal";
import {
    getSocketStartPrintObservable,
    resetSocketStartPrintSubject,
    sendPrintFile
} from "../../services/socketEventsService";
import axios from "axios";
import {Dialog} from "@material-ui/core";
import {LibraryTableComponent} from "../library/LibraryTableComponent";

export const FastPrint = (props) => {

    const {printers, setAlert, callback} = props;

    /* Misc data */
    const [fastPrintFile, setFastPrintFile] = useState(null);
    const [fastPrintFileName, setFastPrintFileName] = useState(null);
    const [fastPrintBuild, setFastPrintBuild] = useState(null);
    const [identifiedUser, setIdentifiedUser] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);
    const [downloadFileUrl, setDownloadFileUrl] = useState(null);
    const [showLibraryPicking, setShowLibraryPicking] = useState(false);
    const [pickedFilename, setPickedFilename] = useState(null);

    /* Controllable printers API stuff */
    const controllablePrinters = printers.filter(printer => printer.controllable_printer_id !== undefined);
    const apiConnections = useGetData(
        'controllable_printer_api_connections',
        'controllable_printer_api_connections?organization=' + getCurrentUser().organization.id
    );

    /* Pieces from the library */
    const pieces = useGetData(`piecesData`,'pieces',{'user.id': getCurrentUser().id});

    /* Selected printer */
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const printerUsesFile = selectedPrinter?.brand?.printer_brand_data.has_api_start_print_with_file;

    const handlePrinterSelection = (newSelectedPrinter) => {
        if(newSelectedPrinter.id !== selectedPrinter?.id){
            setFastPrintBuild(null);
            setFastPrintFile(null);
        }

        setControllablePrinterApiSelected(getControllablePrinterApiSelected(newSelectedPrinter.brand));
        setSelectedPrinter(newSelectedPrinter);
    };

    /* Stuff used to load a build from the printer API */
    const [controllablePrinterApiSelected, setControllablePrinterApiSelected] = useState(null);

    const getControllablePrinterApiSelected = (brand) => {
        for(let i = 0; i < apiConnections.data.length; i++) {
            if(apiConnections.data[i].brand.name.toLowerCase() === brand.name.toLowerCase()) {
                return apiConnections.data[i];
            }
        }
        return null;
    }

    /* Actions to perform when starting the print */
    const [digitCodeStartVisible, setDigitCodeStartVisible] = useState(false);

    const handleStartPrint = (user) => {
        setIdentifiedUser(user);

        if(printerUsesFile)
            launchPrintWithFileControllablePrinter();
        else
            launchPrintControllablePrinter();
    };

    const saveTaskOnSuccess = () => {
        /* Get task name */
        let taskName = null;
        if(printerUsesFile)
            taskName = fastPrintFile.name;
        else
            taskName = fastPrintBuild.name;

        /* Save task in database (Gantt view) */
        postTaskFastPrint(identifiedUser, selectedPrinter.id, taskName, fastPrintBuild)
            .then(response => response.json())
            .then(task => {
                callback();
            })
            .catch(error => {
                console.log(error)
                setAlert({message: 'An error occurred.', status: "error", date: new Date()});
            })
    };

    const launchPrintControllablePrinter = () => {
        postStartPrint(selectedPrinter.controllable_printer_id, selectedPrinter.brand.printer_brand_data.code, fastPrintBuild.id)
            .then(response => {
                if(response.ok) {
                    setIsWaitingForPrintStartEvent(true);
                    // Open loading modal and wait for print start event (received in useEffect - Step 3.1)
                    handleControllablePrinterModalOpen(
                        'Starting printing',
                        'Waiting for print start...',
                        [],
                        true,
                        false
                    );
                } else {
                    // Open error modal if response error
                    openControllablePrinterErrorModal(0);
                }
            })
            .catch(() => openControllablePrinterErrorModal(0));
    };

    const launchPrintWithFileControllablePrinter = async () => {
        /* If we pick a file from the library, we just send the download link directly.
           If we provide a new file, we to upload it before getting the download link */

        let downloadUrl = downloadFileUrl; // Try either from the library or with the uploaded file

        if (downloadFileUrl === null) {
            const uploadResponse = await uploadFileBeforePrintLaunch();

            if(!uploadResponse){
                alert('Something went wrong during the upload of the file.');
                return;
            }

            downloadUrl = uploadResponse.url;
        }

        if(downloadUrl === null){
            console.error('Unable to get file.');
            return;
        }

        sendPrintFile({
            'filename': pickedFilename ? pickedFilename : fastPrintFileName,
            'download_url': downloadUrl,
            'organization': selectedPrinter.organization.id,
            'controllable_printer_id': selectedPrinter.controllable_printer_id,
            'brand_code': selectedPrinter.brand.printer_brand_data.code
        });
        setIsWaitingForPrintStartEvent(true);
        // Open loading modal and wait for print start event (received in useEffect - Step 3.1)
        handleControllablePrinterModalOpen(
            'Starting printing',
            'Waiting for printer to start...',
            [],
            true,
            false
        );
    };

    const uploadFileBeforePrintLaunch = async () => {
        setFileLoading(true);
        // Upload file
        let data = new FormData();
        data.append('file', fastPrintFile);

        // Send the request
        return axios.post(process.env.REACT_APP_API_ADDRESS + 'upload', data, {
            headers: {
                'Content-Type': "multipart/form-data",
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            setFileLoading(false);
            if(response.status === 201){
                console.log('Upload success')

                return getGeneralDownloadUrl(fastPrintFile.name)
                    .catch(error => {alert('Something went wrong.'); console.error(error)})
                    .then(response => response.json()).then(response => {
                        return response;
                    });
            }
            else{
                alert('error');
                return Promise.reject('Upload failed');
            }
        })
        .catch(error => {
            setFileLoading(false);
            alert('An error occurred.')
            console.log(error)
            return Promise.reject(error);
        });

    };

    /* Launch print modal */
    // States for controllable printer print errors
    const [isControllablePrinterModalOpen, setIsControllablePrinterModalOpen] = useState(false);
    const [controllablePrinterModalDescription, setControllablePrinterModalDescription] = useState('Print error');
    const [controllablePrinterModalTitle, setControllablePrinterModalTitle] = useState('An error occurred');
    const [controllablePrinterModalButtons, setControllablePrinterModalButtons] = useState([]);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [isModalCancelButton, setIsModalCancelButton] = useState(true);
    const [startControllablePrintEvent, setStartControllablePrintEvent] = useState(null);
    const [isWaitingForPrintStartEvent, setIsWaitingForPrintStartEvent] = useState(false);
    const isWaitingForPrintStartEventTimeout = useRef();

    useEffect(() => {
        // Subscribe to start print events (used to check if print started)
        const startPrintSubscription = getSocketStartPrintObservable().subscribe({
            next: (startPrintData) => {
                setStartControllablePrintEvent(startPrintData);
            }
        });

        return(() => {
            setIsControllablePrinterModalOpen(false);
            setStartControllablePrintEvent(null);
            startPrintSubscription.unsubscribe();
        });
    }, []);

    useEffect(() => {
        // Add timeout while waiting for start print event, if no event in 90 seconds stop waiting for event and display error modal
        if(isWaitingForPrintStartEvent) {
            if(isWaitingForPrintStartEventTimeout.current) {
                clearTimeout(isWaitingForPrintStartEventTimeout.current);
            }
            isWaitingForPrintStartEventTimeout.current = setTimeout(() => {
                openControllablePrinterErrorModal(0, 'No response from the service.');
                setIsWaitingForPrintStartEvent(false);
            }, 300 * 1000); // 300 seconds
        } else {
            clearTimeout(isWaitingForPrintStartEventTimeout.current);
        }
    }, [isWaitingForPrintStartEvent]);

    useEffect(() => {
        if(startControllablePrintEvent) {
            if (startControllablePrintEvent.status === 'success') {
                // Reset start print data
                saveTaskOnSuccess();
                resetSocketStartPrintSubject();
            } else if (startControllablePrintEvent.status === 'partial success') {
                // If unable to check if print started, show error modal
                saveTaskOnSuccess();
                openControllablePrinterErrorModal(410);
            } else {
                // If error, show error modal
                openControllablePrinterErrorModal(startControllablePrintEvent.code, startControllablePrintEvent.message);
            }

            setIsWaitingForPrintStartEvent(false);
        }
    }, [startControllablePrintEvent]);

    const openControllablePrinterErrorModal = (errorCode, errorMessage = null) => {
        const modalData = getStartPrintErrorModalDataByErrorCode(errorCode);
        const description = errorMessage !== null ? modalData.description + ' (Details: ' + errorMessage + ')' : modalData.description;
        handleControllablePrinterModalOpen(modalData.title, description, [], false, true);
    }

    const handleControllablePrinterModalOpen = (title = 'Print error', description = 'An error occurred',
                                                buttons = [], loading = false, isCancelButton = true) => {
        setControllablePrinterModalTitle(title);
        setControllablePrinterModalDescription(description);
        setControllablePrinterModalButtons(buttons);
        setIsControllablePrinterModalOpen(true);
        setIsModalLoading(loading);
        setIsModalCancelButton(isCancelButton);
    }

    const handleControllablePrinterModalClose = () => {
        setIsControllablePrinterModalOpen(false);
        setControllablePrinterModalTitle('Print error');
        setControllablePrinterModalDescription('An error occurred');
        setControllablePrinterModalButtons([]);
        setIsModalLoading(false);
        setStartControllablePrintEvent(null);
    }

    const onPickingFromLibrary = (piece) => {
        getPieceDownloadUrl(piece)
            .catch(error => {alert('Something went wrong.'); console.error(error)})
            .then(response => response.json()).then(response => {
                setPickedFilename(piece.name);
                setDownloadFileUrl(response.url);
                setFastPrintFile(null);
                setShowLibraryPicking(false);
            });
    };

    if(apiConnections.isLoading || pieces.isLoading) return <Loading/>
    if(apiConnections.isError) return <Error errorMessage={apiConnections.error}/>
    if(pieces.isError) return <Error errorMessage={pieces.error}/>

    return (
        <>
            <DigitCode visible={digitCodeStartVisible} setVisible={setDigitCodeStartVisible}
                       callback={handleStartPrint} errorCallback={() => null}/>

            {isControllablePrinterModalOpen &&
                <ControllablePrinterModal
                    title={controllablePrinterModalTitle}
                    description={controllablePrinterModalDescription}
                    buttons={controllablePrinterModalButtons}
                    cancelCallback={() => handleControllablePrinterModalClose()}
                    loading={isModalLoading}
                    isCancelButton={isModalCancelButton}
                />
            }

            <div className="main__fast-print">
                <h2>1. Choose a printer</h2>
                {
                    controllablePrinters.map((printer, i) => {
                        const selectedClass = printer.id === selectedPrinter?.id ? 'selected' : '';

                        return (
                            <React.Fragment key={printer.id}>
                                <button className={selectedClass} onClick={() => handlePrinterSelection(printer)}>
                                    {printer.name}
                                </button>
                                &nbsp;
                            </React.Fragment>
                        )
                    })
                }

                {
                    !printerUsesFile && controllablePrinterApiSelected !== null ?
                        <>
                            <h2>2. Select your build</h2>
                            <CardSlicerBuildTable
                                setIsBuildTableOpen={() => null}
                                apiConnection={controllablePrinterApiSelected}
                                handleBuildChange={(buildData) => setFastPrintBuild(buildData)}
                                selectedPrinters={[selectedPrinter]}
                                tableHeight={300}
                            />
                            <p>Selected build: {fastPrintBuild !== null ? fastPrintBuild.name : 'none'}</p>
                        </>

                    : fileLoading ?
                        <>
                            <Loading/>
                            File transfer in progress...
                        </>

                    : printerUsesFile &&
                        <>
                            <h2>2. Select your sliced file</h2>
                            <SimpleDropZoneOneFile
                                setAlert={setAlert}
                                uploadedFile={fastPrintFile}
                                setUploadedFile={setFastPrintFile}
                                setFastPrintFileName={setFastPrintFileName}
                            />
                            <div className="or-separator">or</div>
                            <div className="t-center">
                                <button onClick={() => setShowLibraryPicking(true)}>Print a file from the library</button>
                                {
                                    pickedFilename !== null && <p>Selected file: {pickedFilename}</p>
                                }
                            </div>
                        </>
                }

                {
                    selectedPrinter !== null && (fastPrintBuild !== null || fastPrintFile !== null || downloadFileUrl !== null) &&
                        <div>
                            <h2>3. Launch the print</h2>
                            <button onClick={() => setDigitCodeStartVisible(true)}>Start print</button>
                        </div>
                }

                <Dialog open={showLibraryPicking} onClose={() => setShowLibraryPicking(false)} maxWidth={null}>
                    <div style={{width: '800px'}} className='padding-normal'>
                        <LibraryTableComponent pieces={pieces} pickingCallback={(piece) => onPickingFromLibrary(piece)} />
                    </div>
                </Dialog>
            </div>
        </>
    )

};
