import React, {useCallback, useState} from "react";
import {Drawer, makeStyles} from '@material-ui/core';
import {DropZone} from "../common/DropZone";
import {getCurrentUser} from "../CommonFunctions";

const useStyles = makeStyles({
    paper: {
        left: "50%",
    }
});

export const AddPieceComponent = (props) => {
    const classes = useStyles();

    const {successCallback, errorCallback} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);

    const onPieceUploaded = useCallback((response) => {
        successCallback('The piece has been uploaded.');
    }, []);

    const onPieceNotUploaded = useCallback((response) => {
        errorCallback('An error occurred while uploading the piece.');
    }, []);

    const createRequestBody = useCallback((file) => {
        return {
            user: process.env.REACT_APP_API_URI + `users/${getCurrentUser().id}`,
            sent: 1,
            name: file.name,
            size: file.size
        };
    }, []);


    return (
        <React.Fragment>
            <button className="button-link" id="pieces__add" onClick={() => setRightPartIsOpen(!rightPartIsOpen)}><i className="fa fa-plus"/> Add a piece</button>

            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>

                <div id="main__create-project">

                    <div className="block">
                        <h2>Add pieces</h2>

                        <DropZone
                            uploadEndpoint={'pieces'}
                            createRequestBody={createRequestBody}
                            onFileUploaded={onPieceUploaded}
                            onFileNotUploaded={onPieceNotUploaded}
                        />
                    </div>

                </div>

            </Drawer>
        </React.Fragment>
    )

}

