import React, {useState} from 'react';
import {getConvertedDuration, getFormattedDuration} from "../../CommonFunctions";
import {TextField} from "@material-ui/core";
import InputMask from "react-input-mask";
import {Controller, useForm} from "react-hook-form";

export const CardSmartFarm = (props) => {

    const {picCard, setIsActive, refreshGlobalState, globalCardData, handleContinueCard} = props;

    const card = picCard.card;

    const [, refreshState] = useState();

    // Init form management
    const {handleSubmit, setValue, control} = useForm();

    const [desiredTemperature, setDesiredTemperature] = useState();
    const [desiredHumidity, setDesiredHumidity] = useState();

    // const handleClickMachine = (e) => {
    //     setSelectedMethod(e.target.innerHTML);
    //     card.method = e.target.innerHTML;
    //     refreshState({});
    // }
    //
    // const handleChangeTime = (duration) => {
    //     if(duration){
    //         setConvertedSelectedTime(getConvertedDuration(duration));
    //         card.duration = getConvertedDuration(duration);
    //     }
    //     else {
    //         setConvertedSelectedTime(0);
    //         card.duration = 0;
    //     }
    //
    //     refreshState({});
    // }

    const handleClickContinue = () => {
        handleContinueCard(picCard);
        setIsActive(false);
    }

    return (
        <div className="main__card-smart-farm">

            <h2>Enter your Smart Farm parameters</h2>

            <h3>Desired temperature</h3>
            <Controller
                control={control}
                name='desired_temperature'
                defaultValue={card.desired_temperature ? card.desired_temperature : null}
                rules={{required: false}}
                render={() => (
                    <TextField
                        label="Desired temperature (°C)"
                        type="number"
                        variant="outlined"
                        size="small"
                        defaultValue={card.desired_temperature ? card.desired_temperature : null}
                        onChange={(e) => {
                            card.desired_temperature = parseInt(e.target.value);
                            refreshGlobalState({});
                        }}
                    />
                )}
            />

            <h3>Desired humidity</h3>
            <Controller
                control={control}
                name='desired_humidity'
                defaultValue={card.desired_humidity ? card.desired_humidity : null}
                rules={{required: false}}
                render={() => (
                    <TextField
                        label="Desired humidity (%)"
                        type="number"
                        variant="outlined"
                        size="small"
                        defaultValue={card.desired_humidity ? card.desired_humidity : null}
                        onChange={(e) => {
                            card.desired_humidity = parseInt(e.target.value);
                            refreshGlobalState({});
                        }}
                    />
                )}
            />

            <div className="button-zone">
                <button onClick={handleClickContinue}><h2>Continue</h2></button>
            </div>
        </div>
    )
}
