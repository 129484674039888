import axios from "axios";
import {useQuery} from "react-query";
import {getAutomationTokenFromLocalStorage} from "../services/automation/AutomationService";
import {getCurrentUser} from "../components/CommonFunctions";

const token = getAutomationTokenFromLocalStorage();

/* Get base url for requests */
const getBaseUrl = () => {
    let url = process.env.REACT_APP_BASE_PROTOCOL + '://' + process.env.REACT_APP_BASE_URL;
    if(process.env.REACT_APP_AUTOMATION_PORT !== '') {
        url += ':' + process.env.REACT_APP_AUTOMATION_PORT;
    }
    if(process.env.REACT_APP_AUTOMATION_URI) {
        url += process.env.REACT_APP_AUTOMATION_URI;
    }
    return url + '/';
}

/* add organization param to params list */
const setParamsWithOrganization = (params) => {
    const user = getCurrentUser();
    let organization = null;
    if(user) {
        organization = user.organization.id;
    }
    params.organization = organization;
    return params;
}

const getFormattedParams = (params) => {
    let formattedParams = '';
    if(params) {
        params = setParamsWithOrganization(params);
        for(let [key, value] of Object.entries(params)) {
            if(formattedParams === '') {
                formattedParams += '?' + key + '=' + value;
            } else {
                formattedParams += '&' + key + '=' + value;
            }
        }
    }
    return formattedParams;
}


export const useGetAutomationData = (key, path, params = {}, skip = false) => {
    const getData = async () => {
        let header = token ? {'Authorization': `Bearer ${token}}`} : null;
        const {data} = await axios.get(getBaseUrl() + path, {
            headers: header,
            params: setParamsWithOrganization(params)
        });

        if (data['hydra:member']) {
            return data['hydra:member'];
        } else {
            return data;
        }
    }
    return useQuery(key, getData, {refetchOnWindowFocus: false, skip: skip});
}

export const fetchAutomationData = async (path, params=null) => {
    let url = getBaseUrl() + path + getFormattedParams(params);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

export const postStartPrint = (controllablePrinterId, brandCode, buildId) => {
    return fetch(getBaseUrl() + 'controllable_printers/' + controllablePrinterId + '/print' + getFormattedParams(setParamsWithOrganization({brand_code: brandCode})), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({build_id: buildId})
    });
}

export const getPieceDownloadUrl = (piece) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + `pieces/${piece.id}/download`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
};

export const getGeneralDownloadUrl = (filename) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + `download/${filename}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
};