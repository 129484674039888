import {Dialog, DialogContent, makeStyles} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {Popover, Table, Whisper} from "rsuite";
import {downloadAPIFile, useGetData} from "../../api/useFetch";
import {Loading} from "../common/Loading";
import {getFormattedDate} from "../CommonFunctions";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {dateToTimestampInSec} from "../../utils/date";
import {AlertMessage} from "../common/AlertMessage";
import {addQualification} from "../../api/apiMember";
import {DropZone} from "../common/DropZone";
import FileViewer from 'react-file-viewer';

const useStyles = makeStyles({
    dialog_paper: {
        width: "800px",
        maxWidth: "none",
        padding: "0 15px"
    }
});

export const QualificationTable = (props) => {
    const classes = useStyles();

    const {member, members} = props;
    const [alert, setAlert] = useState();

    const printers = useGetData('printers', 'printers', {'organization.id': member.id});

    // Member qualification popup
    const [isQualificationTableOpen, setIsQualificationTableOpen] = useState(false);
    const [openPrinterId, setOpenPrinterId] = useState(null);
    const [openDocumentQualificationId, setOpenDocumentQualificationId] = useState(null);
    const [openViewDocumentQualification, setOpenViewDocumentQualification] = useState(null);

    const getQualificationDate = (printer) => {
        const qualification = member.qualifications.sort((q1, q2) => q2.id - q1.id).find(qualification => qualification.printer.id === printer.id);
        if(qualification)
            return qualification.date;
        else
            return null;
    };

    const getLastPrinterQualification = (printer) => {
        return member.qualifications.sort((q1, q2) => q2.id - q1.id).find(qualification => qualification.printer.id === printer.id);
    }

    const printersTableData = printers.data ? printers.data.map(printer => {
        return {printer_id: printer.id, name: printer.name, qualification: getLastPrinterQualification(printer), date: getQualificationDate(printer)}
    }) : [];

    const isBeforeToday = (date) => {
        if(date === null) return false;
        return new Date(date * 1000) < new Date().setHours(0, 0, 0, 0);
    }

    const handleDateChange = (date) => {
        addQualification({
            user: process.env.REACT_APP_API_URI + `users/${member.id}`,
            printer: process.env.REACT_APP_API_URI + `printers/${openPrinterId}`,
            date: dateToTimestampInSec(date)
        })
            .then(r => r.json())
            .then(r => {
                setAlert({message: 'Qualification updated.', status: 'success', date: new Date()})
                members.refetch();
            })
            .catch(e => setAlert({message: 'Something went wrong.', status: 'error', date: new Date()}));

        setOpenPrinterId(null);
    };

    const onFileUploaded = useCallback((response) => {
        setAlert({message: 'Qualification document added.', status: 'success', date: new Date()})
        members.refetch();
        setOpenDocumentQualificationId(null);
    }, []);

    const onFileNotUploaded = useCallback((response) => {
        setAlert({message: 'Something went wrong.', status: 'error', date: new Date()})
        members.refetch();
        setOpenDocumentQualificationId(null);
    }, []);

    return (
        <>
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}

            <Whisper placement="top" trigger="hover" speaker={<Popover><span>Manage user qualification</span></Popover>}>
                <button className="button-icon" onClick={() => setIsQualificationTableOpen(true)}><i className="fa fa-book"/></button>
            </Whisper>

            <Dialog
                classes={{paper: classes.dialog_paper}}
                open={isQualificationTableOpen}
                onClose={() => setIsQualificationTableOpen(false)}
            >
                <DialogContent>
                    {
                        printers.isLoading ? <Loading/> :
                            <>
                                <Dialog open={openPrinterId !== null} onClose={() => setOpenPrinterId(null)}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant="static"
                                            disableToolbar
                                            format="MM/dd/yyyy"
                                            InputProps={{ classes: { root: {fontFamily: "Cabin"} } }}
                                            onChange={handleDateChange}
                                            value={new Date()}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Dialog>

                                <Dialog open={openDocumentQualificationId !== null} onClose={() => setOpenDocumentQualificationId(null)}>
                                    <div style={{width: '500px'}}>
                                        <DropZone
                                            uploadEndpoint={`qualifications/${openDocumentQualificationId}/upload`}
                                            createRequestBody={() => {return {has_file: true}}}
                                            onFileUploaded={onFileUploaded}
                                            onFileNotUploaded={onFileNotUploaded}
                                            containerData={null}
                                        />
                                    </div>
                                </Dialog>

                                <Dialog open={openViewDocumentQualification !== null} onClose={() => setOpenViewDocumentQualification(null)}>
                                    <div style={{width: '500px'}}>
                                        <FileViewer
                                            fileType={openViewDocumentQualification?.file_extension}
                                            filePath={openViewDocumentQualification?.file_url}
                                        />
                                    </div>
                                </Dialog>

                                <h2>Manage qualifications</h2>
                                <Table
                                    hover={true}
                                    autoHeight={true}
                                    data={printersTableData}
                                    bordered={true}
                                    cellBordered={true}
                                >
                                    <Table.Column flexGrow={2}>
                                        <Table.HeaderCell children='Printers'/>
                                        <Table.Cell dataKey="name"/>
                                    </Table.Column>
                                    <Table.Column flexGrow={2}>
                                        <Table.HeaderCell children='Qualification date'/>
                                        <Table.Cell dataKey="date">{rowData =>
                                            <span className={`${isBeforeToday(rowData.date) ? 'qualification-expired' : ''}`}>
                                                {rowData.date !== null ? getFormattedDate(rowData.date) : '-'}
                                                {isBeforeToday(rowData.date) && ' (Expired)'}
                                            </span>
                                        }</Table.Cell>
                                    </Table.Column>
                                    <Table.Column flexGrow={5}>
                                        <Table.HeaderCell children='Actions'/>
                                        <Table.Cell>{rowData =>
                                            <>
                                                <button className="qualification-button" onClick={() => setOpenPrinterId(rowData.printer_id)}>
                                                    Update qualification
                                                </button>
                                                {
                                                    rowData.qualification &&
                                                        <button className="qualification-button" onClick={() => setOpenDocumentQualificationId(rowData.qualification.id)}>
                                                            Add document
                                                        </button>
                                                }
                                                {
                                                    rowData.qualification?.has_file &&
                                                        <>
                                                            <button className="qualification-button" onClick={() => setOpenViewDocumentQualification(rowData.qualification)}>
                                                                <i className="fa fa-eye"/>
                                                            </button>
                                                            <button className="qualification-button"
                                                                    onClick={() => downloadAPIFile(`qualifications/${rowData.qualification.id}/download`, rowData.name)}>
                                                                <i className="fa fa-download"/>
                                                            </button>
                                                        </>
                                                }
                                            </>
                                        }</Table.Cell>
                                    </Table.Column>
                                </Table>
                            </>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};