import React, {useEffect, useMemo, useState} from "react";
import {downloadAPIFile} from "../../api/useFetch";
import {getFormattedDate, isUserGranted} from "../CommonFunctions"
import {deletePiece} from "../../api/apiLibrary"
import {Loading} from "../common/Loading";
import {Avatar} from "../common/Avatar";
import {Checkbox, Drawer, makeStyles} from "@material-ui/core";
import {PieceViewer} from "../viewer/PieceViewer";
import Modal from "../common/Modal";
import {useParams} from "react-router-dom";
import {Tag} from "../common/Tag";
import {getFiles3DNames} from "../../utils/library_utils";
import {getFileExtension} from "../../utils/library_utils";

const useStyles = makeStyles({
    paper: {
        left: "50%",
        background: "#f5f5f5"
    },
});

export const LibraryTableComponent = (props) => {
    const classes = useStyles();

    const {pieces, checkedPieces, setCheckedPieces, setIsChecked, pickingCallback, successCallback, errorCallback} = props;

    const isPickingMode = pickingCallback !== null && pickingCallback !== undefined;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [pieceData, setPieceData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedPiece, setSelectedPiece] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [_, refreshState] = useState();

    // Get open piece ID from url
    let {pieceId} = useParams();

    useEffect(() => {
        if(!pieces.isLoading && pieceId !== undefined && pieceId !== null){
            const piece = pieces.data.filter(piece => piece.id === parseInt(pieceId));

            if(piece.length === 1){
                setPieceData(piece[0]);
                setRightPartIsOpen(true);
            }
            window.history.pushState({}, "library", "/library");
        }
    }, [pieces.isLoading]);

    const handleCheck = (e, pieceId) => {
        e.stopPropagation();
        let tmpCheckedPieces = checkedPieces;

        if(!tmpCheckedPieces.includes(pieceId))
            tmpCheckedPieces.push(pieceId);
        else
            tmpCheckedPieces = tmpCheckedPieces.filter(id => id !== pieceId);

        setCheckedPieces(tmpCheckedPieces);
        setIsChecked(tmpCheckedPieces.length > 0);
        refreshState({});
    };

    const handleDelete = async (pieceId) => {
        deletePiece(pieceId)
            .then(() => {
                pieces.refetch();
                successCallback('The piece has been deleted.');
            })
            .catch(() => errorCallback('This piece is used.'));
        cancelModal();
    }
    const cancelModal = () => {
        setShowModal(false);
        setIsDeleting(false);
    }

    const handleLineClick = (piece) => {
        if(!isPickingMode) return;
        pickingCallback(piece);
    };

    const files3DNames = useMemo(() => getFiles3DNames(pieces), [pieces.data]);

    return (
        pieces.data.filter(piece => files3DNames.includes(piece.name)).length > 0 ?
            <div id="library__files">
                <table id="table">
                    <thead>
                        <tr>
                            {!isPickingMode && <th style={{width: '5%'}}/>}
                            <th style={{width: '20%'}}>Name</th>
                            <th style={{width: '15%'}}>Projects</th>
                            <th style={{width: '10%'}}>Owner</th>
                            <th style={{width: '10%'}}>Format</th>
                            <th style={{width: '15%'}}>Import date</th>
                            {!isPickingMode && <th style={{width: '25%'}}/>}
                        </tr>
                    </thead>
                    <tbody>
                        {pieces.data.filter(piece => files3DNames.includes(piece.name))
                            .sort((a, b) => b.id - a.id).map((piece) => (
                            <tr
                                key={piece.id}
                                onClick={() => handleLineClick(piece)}
                                style={{cursor: `${isPickingMode ? 'pointer' : ''}`}}
                            >
                                {
                                    !isPickingMode &&
                                        <td>
                                            <Checkbox key={piece.id} style={{padding: 0}}
                                                      onClick={(e) => handleCheck(e, piece.id)} checked={checkedPieces.length > 0 && checkedPieces.includes(piece.id)}/>
                                        </td>
                                }
                                <td>{piece.name}</td>
                                <td>
                                    {
                                        piece.pieces_in_project.length > 0 ? (
                                            <>
                                                <span className="project__name" style={{background: piece.pieces_in_project[0].project.color.hex_code}}>
                                                    {piece.pieces_in_project[0].project.name}
                                                </span>
                                                {piece.pieces_in_project.length > 2 && <span> (+ {piece.pieces_in_project.length - 1} others)</span>}
                                                {piece.pieces_in_project.length === 2 && <span> (+ {piece.pieces_in_project.length - 1} other)</span>}
                                            </>
                                        ) : '-'
                                    }

                                </td>
                                <td><Avatar user={piece.user} size="small"/></td>
                                <td><Tag content={getFileExtension(piece.name)}/></td>
                                <td>{ getFormattedDate(piece.upload_date) }</td>
                                {
                                    !isPickingMode &&
                                    <td className="action">
                                        <button className="button-link button-icon"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                downloadAPIFile(
                                                    `pieces/${piece.id}/download`,
                                                    piece.name
                                                )
                                            }}
                                        ><i className="fa fa-cloud-download-alt"/></button>
                                        &nbsp;
                                        {
                                            isUserGranted('ROLE_DELETE_PIECE') &&
                                                <button
                                                    className="button-link button-icon btn-delete-piece"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedPiece(piece);
                                                        setIsDeleting(true);
                                                        setShowModal(true);
                                                    }}
                                                >
                                                    <i className="fa fa-times" />
                                                </button>
                                        }
                                        &nbsp;
                                        {
                                            getFileExtension(piece.name) === 'stl' &&
                                                <button
                                                    className="button-link button-icon btn-delete-piece"
                                                    onClick={(e) => {
                                                        setPieceData(piece);
                                                        if(!isDeleting)
                                                            setRightPartIsOpen(true);
                                                    }}
                                                >
                                                    <i className="fa fa-eye" />
                                                </button>
                                        }
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>

                {showModal &&
                    <Modal
                        title="Delete a part"
                        description={`Are you sure you want to delete the part <strong>${selectedPiece.name}</strong>?`}
                        buttons={[
                            {label: `Yes, delete <strong>${selectedPiece.name}</strong>`, callback: () => handleDelete(selectedPiece.id)}
                        ]}
                        cancelCallback={() => cancelModal()}
                    />
                }

                <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>
                    {
                        pieceData != null
                        ? <PieceViewer piece={pieceData}/>
                        : <Loading/>
                    }
                </Drawer>
            </div>
        :
            <p><em>No 3D file in the library</em></p>
    )
}