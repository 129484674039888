import {OutlinedCard} from "../../common/OutlinedCard";
import {getFormattedRoundedDate} from "../../../CommonFunctions";
import React, {useState} from "react";
import {getSortedData} from "../../../../services/TablesService";
import {
    createCsvData,
    DOOR_STATES,
    downloadCsvData,
    FARM_EVENT_TYPES,
    ON_OFF_STATES
} from "../../../../services/FarmService";

export const getLedColorEventType = (value) => {
    switch(value) {
        case 0: return 'Light off';
        case 2: return 'Red light';
        case 3: return 'Green light';
        case 4: return 'Orange light';
        case 5: return 'Handddle light';
        case 6: return 'Blue light';
        case 7: return 'White light';
        default: return 'Light change';
    }
}

export const getEventType = (type, value) => {
    switch (type) {
        case FARM_EVENT_TYPES.ON_OFF: return value === ON_OFF_STATES.ON ? 'System start' : 'System stop';
        case FARM_EVENT_TYPES.LED_COLOR: return getLedColorEventType(value);
        case FARM_EVENT_TYPES.OPEN_DOOR: return value === DOOR_STATES.CLOSED ? 'Close door' : 'Open door';
        case FARM_EVENT_TYPES.TEMPERATURE: return (value > 0 ? 'Temperature change: ' + value + '°C' : 'Heater off');
        case FARM_EVENT_TYPES.AIR_EXTRACTION_COMMAND: return 'Air extraction change: ' + value + '%';
        case FARM_EVENT_TYPES.RELAY_COMMAND: return 'Stop printer';
        case FARM_EVENT_TYPES.DEHUMIDIFIER: return 'Dehumidifier ' + (value === 1 ? 'on' : 'off');
        case FARM_EVENT_TYPES.PROFILE: return value !== 0 ? 'Profile activated: ' + value : 'Profile canceled';
        default: return '--';
    }
};

export const HistoryEventsBlock = (props) => {

    const {historyEvents} = props;

    const [showAutomations, setShowAutomations] = useState(false);

    const keptEvents = historyEvents.filter((event) => event.type !== FARM_EVENT_TYPES.LED_COLOR);
    const sortedHistoryEvents = getSortedData(keptEvents, 'date', 'desc');

    /* Get user value depending on if event is an automatic event or if event has a user */
    const getEventUser = (event) => {
        if(event.is_automatic) {
            return 'Automatic';
        }
        if(event.user) {
            return event.user.firstname + ' ' + event.user.lastname;
        }
        return '--';
    }

    const handleShowAutomations = (showAutomations) => {
        setShowAutomations(showAutomations);
    };

    const exportEvents = () => {
        let csvData = [['date', 'event_type', 'user']];

        for(const event of sortedHistoryEvents.filter(e => !e.is_automatic)){
            const value = event.farm_profile ? event.farm_profile.name : event.value;
            csvData.push([
                getFormattedRoundedDate(new Date(event.date)).toString(),
                getEventType(event.type, value),
                getEventUser(event)
            ])
        }

        downloadCsvData(createCsvData(csvData), 'export_events')
            .catch(() => alert('An error occurred while creating csv'));
    };

    return(
        <OutlinedCard>
            <div className="farm__dialog_history_events">
                <h2>History events</h2>
                {/*<div className="toggle">*/}
                {/*    <Toggle checked={showAutomations} onChange={showAutomations => handleShowAutomations(showAutomations)}/>*/}
                {/*    <span className="">Show Smart Farm automations</span>*/}
                {/*</div>*/}
                <button onClick={exportEvents}><i className="fa fa-download"/> Export events</button>
                {sortedHistoryEvents.length === 0 &&
                    <p>No history event</p>
                }
                {sortedHistoryEvents.length > 0 &&
                    <div className="history-events-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Event type</th>
                                <th>User</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedHistoryEvents.filter(e => showAutomations || !e.is_automatic).map(event => {
                                const value = event.farm_profile ? event.farm_profile.name : event.value;
                                return (
                                    <tr key={event.id}>
                                        <td>{getFormattedRoundedDate(new Date(event.date)).toString()}</td>
                                        <td>{getEventType(event.type, value)}</td>
                                        <td>{getEventUser(event)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </OutlinedCard>
    );
}
