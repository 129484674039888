import React, {useState} from "react";
import {useGetData} from "../../api/useFetch";
import {AddPieceComponent} from './AddPiece';
import {LibraryTableComponent} from "./LibraryTableComponent";
import {LibraryOtherTableComponent} from "./LibraryOtherTableComponent";
import {LinkProjectComponent} from "./LinkProject"
import {UnlinkProjectComponent} from "./UnlinkProject"
import {getCurrentUser, isUserGranted} from "../CommonFunctions";
import {AlertMessage} from "../common/AlertMessage";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

export const Library = () => {

    const [checkedPieces, setCheckedPieces] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [alertDisplay, setAlertDisplay] = useState();

    const projects = useGetData(`projectsData`, 'projects');
    const pieces = useGetData(`piecesData`,'pieces',{'user.id': getCurrentUser().id});

    const successCallback = (message) => {
        pieces.refetch().then(() => {
            setAlertDisplay({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        pieces.refetch().then(() => {
            setAlertDisplay({message: message, status: "error", date: new Date()});
        });
    };

    if(pieces.isLoading) return <Loading/>
    if(pieces.isError) return <Error errorMessage={pieces.error.message}/>

    return (
        <div id="main__library">
            {alertDisplay ? <AlertMessage key={alertDisplay.date} message={alertDisplay.message} status={alertDisplay.status}/> : null}
            {isUserGranted('ROLE_ADD_PIECE') &&
            <>
                <AddPieceComponent successCallback={successCallback} errorCallback={errorCallback}/>
                &nbsp;
            </>
            }
            {isUserGranted('ROLE_UPDATE_PROJECT') &&
            <>
                <LinkProjectComponent
                    checkedPieces={checkedPieces}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setCheckedPieces={setCheckedPieces}
                    projects={projects}
                    successCallback={successCallback}
                    errorCallback={errorCallback}
                />
                &nbsp;
                <UnlinkProjectComponent
                    checkedPieces={checkedPieces}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setCheckedPieces={setCheckedPieces}
                    projects={projects}
                    successCallback={successCallback}
                    errorCallback={errorCallback}
                />
            </>
            }

            <div id="library__tables">
                <div style={{width: '60%'}}>
                    <h2>3D files</h2>
                    <LibraryTableComponent
                        pieces={pieces}
                        checkedPieces={checkedPieces}
                        setCheckedPieces={setCheckedPieces}
                        setIsChecked={setIsChecked}
                        successCallback={successCallback}
                        errorCallback={errorCallback}
                    />
                </div>
                <div style={{width: '40%'}}>
                    <h2>Other files</h2>
                    <LibraryOtherTableComponent
                        pieces={pieces}
                        successCallback={successCallback}
                        errorCallback={errorCallback}
                    />
                </div>
            </div>
        </div>
    )
}