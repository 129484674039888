export const extensions3D = [
    ".stl", ".3mf", ".obj", ".amf", ".ply", ".vrml", ".wrl", ".x3d",
    ".step", ".stp", ".iges", ".igs", ".fbx", ".dae", ".prt", ".sat", ".brep",
    ".gcode", ".ufp", ".factory", ".gx", ".xpr", ".fff", ".cws", ".cmb",
    ".bgeo", ".x3g", ".g", ".fpp"
];

export const getFileExtension = filename => filename.includes('.') ? filename.split('.').pop().toLowerCase() : '';

export const getFiles3DNames = (pieces) => {
    if(pieces.data === undefined) return [];

    let files3DNames = [];
    for(const piece of pieces.data){
        const ext = `.${getFileExtension(piece.name)}`;
        if(extensions3D.includes(ext))
            files3DNames.push(piece.name);
    }

    return files3DNames;
};
