import React, {useMemo, useState} from "react";
import {downloadAPIFile} from "../../api/useFetch";
import {getFormattedDate, isUserGranted} from "../CommonFunctions"
import {deletePiece} from "../../api/apiLibrary"
import {Avatar} from "../common/Avatar";
import Modal from "../common/Modal";
import {Tag} from "../common/Tag";
import {getFiles3DNames} from "../../utils/library_utils";
import {getFileExtension} from "../../utils/library_utils";

export const LibraryOtherTableComponent = (props) => {
    const {pieces, successCallback, errorCallback} = props;

    const [showModal, setShowModal] = useState(false);
    const [selectedPiece, setSelectedPiece] = useState(null);

    const handleDelete = async (pieceId) => {
        deletePiece(pieceId)
            .then(() => {
                pieces.refetch();
                successCallback('The piece has been deleted.');
            })
            .catch(() => errorCallback('This piece is used.'));
        cancelModal();
    }
    const cancelModal = () => {
        setShowModal(false);
    }

    const files3DNames = useMemo(() => getFiles3DNames(pieces), [pieces.data]);

    return (
        pieces.data.filter(piece => !(files3DNames.includes(piece.name))).length > 0 ?
            <div id="library__files">
                <table id="table">
                    <thead>
                        <tr>
                            <th style={{width: '30%'}}>Name</th>
                            <th style={{width: '15%'}}>Owner</th>
                            <th style={{width: '10%'}}>Format</th>
                            <th style={{width: '25%'}}>Import date</th>
                            <th style={{width: '20%'}}/>
                        </tr>
                    </thead>
                    <tbody>
                        {pieces.data.filter(piece => !(files3DNames.includes(piece.name)))
                            .sort((a, b) => b.id - a.id).map((piece) => (
                            <tr key={piece.id}>
                                <td>{piece.name}</td>
                                <td><Avatar user={piece.user} size="small"/></td>
                                <td><Tag content={getFileExtension(piece.name)}/></td>
                                <td>{ getFormattedDate(piece.upload_date) }</td>
                                <td className="action">
                                    <button className="button-link button-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            downloadAPIFile(
                                                `pieces/${piece.id}/download`,
                                                piece.name
                                            )
                                        }}
                                    ><i className="fa fa-cloud-download-alt"/></button>
                                    &nbsp;
                                    {
                                        isUserGranted('ROLE_DELETE_PIECE') &&
                                            <button
                                                className="button-link button-icon btn-delete-piece"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedPiece(piece);
                                                    setShowModal(true);
                                                }}
                                            >
                                                <i className="fa fa-times" />
                                            </button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {showModal &&
                    <Modal
                        title="Delete a part"
                        description={`Are you sure you want to delete the file <strong>${selectedPiece.name}</strong>?`}
                        buttons={[
                            {label: `Yes, delete <strong>${selectedPiece.name}</strong>`, callback: () => handleDelete(selectedPiece.id)}
                        ]}
                        cancelCallback={() => cancelModal()}
                    />
                }
            </div>
        :
            <p><em>No other file in the library</em></p>
    )
}