const ActionCard = () => (
    <svg id="Calque_1" data-name="Calque 1" viewBox="0 0 60 60" width={50} height={50}>
        <defs>
            <style>{`.cls-1,.cls-3{fill:#00cac0;}.cls-1{stroke:#00cac0;stroke-miterlimit:10;stroke-width:0.25px;}.cls-2{fill:#fff;}.cls-4,.cls-5,.cls-6{fill:none;stroke:#555;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.cls-5{stroke-dasharray:2.39 2.39 2.39 2.39;}.cls-6{stroke-dasharray:2.39 2.39 2.39 2.39 2.39 2.39;}.cls-7{fill:#087d76;}`}</style>
        </defs>
        <path className="cls-1" d="M57,42.48v.78a.49.49,0,0,1-.26.33L29.57,59.25a1.1,1.1,0,0,1-1,0L5.14,45.79V45L28.53,58.52a1.16,1.16,0,0,0,1,0l27-15.61.11-.06A.47.47,0,0,0,57,42.48Z"/>
        <path className="cls-2" d="M57,42.48a.47.47,0,0,1-.26.33l-.11.06-27,15.61a1.16,1.16,0,0,1-1,0L5.14,45,32.72,29.09l.67-.39h0L56.78,42.2A.34.34,0,0,1,57,42.48Z"/>
        <path className="cls-1" d="M37.42,31l-.67.39L9.17,47.35v.76l-6-3.43A.3.3,0,0,1,3,44.42v-.77a.51.51,0,0,1,.26-.35L30.43,27.64a1.1,1.1,0,0,1,.69-.14.34.34,0,0,1,.14,0,.49.49,0,0,1,.21.08Z"/>
        <path className="cls-2" d="M27.71,33.6,14.53,41.21a1.78,1.78,0,0,1-1.6.06l-.11-.06c-.41-.24-.36-.65.12-.92l13.17-7.61a1.75,1.75,0,0,1,1.6-.07l.11.06C28.23,32.91,28.18,33.32,27.71,33.6Z"/>
        <path className="cls-3" d="M35.48,35.77,17.89,45.93A1.07,1.07,0,0,1,17,46l-.06,0c-.23-.14-.2-.37.07-.53L34.57,35.24a1,1,0,0,1,.91,0l.07,0C35.78,35.38,35.75,35.61,35.48,35.77Z"/>
        <path className="cls-3" d="M38.42,37.47,20.82,47.62a1,1,0,0,1-.91,0l-.06,0c-.24-.13-.21-.37.06-.52L37.5,36.94a1,1,0,0,1,.92,0l.06,0C38.71,37.07,38.69,37.31,38.42,37.47Z"/>
        <path className="cls-3" d="M41.35,39.16,23.76,49.32a1,1,0,0,1-.91,0l-.07,0c-.23-.14-.2-.37.07-.53L40.44,38.63a1,1,0,0,1,.91,0l.07,0C41.65,38.77,41.62,39,41.35,39.16Z"/>
        <path className="cls-3" d="M44.29,40.85,26.69,51a1,1,0,0,1-.91,0l-.06,0c-.24-.13-.21-.37.06-.52l17.6-10.16a1,1,0,0,1,.91,0l.06,0C44.59,40.46,44.56,40.7,44.29,40.85Z"/>
        <path className="cls-3" d="M47.22,42.55,29.63,52.71a1,1,0,0,1-.91,0l-.07,0c-.23-.14-.2-.37.07-.53L46.31,42a1,1,0,0,1,.91,0l.07,0C47.52,42.16,47.49,42.39,47.22,42.55Z"/>
        <path className="cls-3" d="M50.16,44.24,32.57,54.4a1,1,0,0,1-.92,0l-.06,0c-.24-.13-.21-.37.06-.52l17.6-10.16a1,1,0,0,1,.91,0l.06,0C50.46,43.85,50.43,44.09,50.16,44.24Z"/>
        <path className="cls-2" d="M9.19,45.27l-3-1.76c-.17-.11-.15-.28.05-.4l3-1.7a.77.77,0,0,1,.69,0l3.05,1.76c.17.1.15.28-.05.39L9.88,45.24A.8.8,0,0,1,9.19,45.27Z"/>
        <line className="cls-4" x1="30" y1="13.71" x2="30" y2="14.71"/>
        <line className="cls-5" x1="30" y1="17.11" x2="30" y2="25.49"/>
        <line className="cls-6" x1="30" y1="26.68" x2="30" y2="39.85"/>
        <line className="cls-4" x1="30" y1="41.05" x2="30" y2="42.05"/>
        <path className="cls-7" d="M39.31,5.13a5.5,5.5,0,0,0-4.45.13,9.09,9.09,0,0,0-1.3.63L33.35,6c-4.25,2.57-7.73,8.28-8.24,13.34A10.19,10.19,0,0,0,25,20.68a8.21,8.21,0,0,0,.1,1.44,5.39,5.39,0,0,0,2.28,4l-6.87-3.95,0,0c-1.66-.84-2.68-2.79-2.68-5.61,0-5.44,3.82-12.07,8.53-14.79,2.44-1.41,4.65-1.5,6.2-.5v0Z"/>
        <path className="cls-3" d="M42.11,10.82c0,5.44-3.83,12.07-8.55,14.79-2.35,1.35-4.47,1.48-6,.61h0l-.14-.07a5.39,5.39,0,0,1-2.28-4,8.21,8.21,0,0,1-.1-1.44,10.19,10.19,0,0,1,.08-1.33c.51-5.06,4-10.77,8.24-13.34l.21-.12a9.09,9.09,0,0,1,1.3-.63,5.5,5.5,0,0,1,4.45-.13l.31.17C41.16,6.18,42.11,8.11,42.11,10.82Z"/>
        <path className="cls-3" d="M37.39,5.08h0a4.15,4.15,0,0,1,1.75.36l.3.17c1.47.84,2.32,2.74,2.32,5.21,0,5.32-3.76,11.82-8.37,14.48a7.41,7.41,0,0,1-3.64,1.12A4.11,4.11,0,0,1,27.88,26v-.06l-.25-.09,0,0a5,5,0,0,1-2.12-3.77,8,8,0,0,1-.1-1.38,9.77,9.77,0,0,1,.09-1.29c.5-5,4-10.6,8.06-13.07l.11-.07.1-.06A10.34,10.34,0,0,1,35,5.59a6.44,6.44,0,0,1,2.4-.51m0-.36a6.82,6.82,0,0,0-2.53.54,9.09,9.09,0,0,0-1.3.63L33.35,6c-4.25,2.57-7.73,8.28-8.24,13.34A10.19,10.19,0,0,0,25,20.68a8.21,8.21,0,0,0,.1,1.44,5.39,5.39,0,0,0,2.28,4l.14.07h0a4.43,4.43,0,0,0,2.2.55,7.75,7.75,0,0,0,3.81-1.16c4.72-2.72,8.55-9.35,8.55-14.79,0-2.71-1-4.64-2.49-5.52l-.31-.17a4.53,4.53,0,0,0-1.92-.41Z"/>
        <path className="cls-2" d="M34.79,13.64,39.32,11v2.82l-4.53,2.61v5.24L32.35,23.1V17.86l-4.53,2.62V17.67l4.53-2.62V9.81l2.44-1.4Z"/>
    </svg>
);

export default ActionCard;