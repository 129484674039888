import React, {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {patchPrinters} from "../../api/apiPrinter";
import {Drawer, makeStyles} from '@material-ui/core';
import {PRINTER_BRANDS} from "../../services/automation/AutomationService";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});

export const EditPrinterComponent = (props) => {
    const classes = useStyles();

    const {printer, brands, models, technologies, printers, controllablePrinters, successCallback, errorCallback} = props;

    const {handleSubmit, control, setValue} = useForm();
    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [selectedTechnology, setSelectedTechnology] = useState(printer.technology);
    const [hasDiameter, setHasDiameter] = useState(printer.technology.has_diameter);
    const [selectedControllablePrinter, setSelectedControllablePrinter] = useState(null);
    const [isControllablePrinterError, setIsControllablePrinterError] = useState(false);
    const [showConnectedPrinterMarkforged, setShowConnectedPrinterMarkforged] = useState(parseInt(printer.brand?.printer_brand_data?.code) === PRINTER_BRANDS.MARKFORGED.code);
    const [showConnectedPrinterUltimaker, setShowConnectedPrinterUltimaker] = useState(parseInt(printer.brand.id) === PRINTER_BRANDS.ULTIMAKER.code);

    /* Check if error while connecting printer to controllable printer */
    const checkIfControllablePrinterApiError = () => {
        if(printer.controllable_printer_id !== undefined && printer.controllable_printer_id !== null) {
            let isError = true;
            if(controllablePrinters.data && controllablePrinters.data !== {}) {
                for(let [key, printerBrand] of Object.entries(controllablePrinters.data)) {
                    if(printerBrand.data) {
                        for(let controllablePrinter of printerBrand.data) {
                            if(controllablePrinter.id_manufacturer === printer.controllable_printer_id) {
                                isError = false;
                                break;
                            }
                        }
                    }
                }
            }
            setIsControllablePrinterError(isError);
        } else if(printer.controllable_printer_id === undefined || printer.controllable_printer_id === null) {
            setIsControllablePrinterError(false);
        }
    }

    useEffect(() => {
       checkIfControllablePrinterApiError();
    }, [controllablePrinters, printer]);

    const onSubmit = async (dataPrinter) => {
        if(showConnectedPrinterMarkforged){
            dataPrinter.controllable_printer_id = setControllablePrinterValueForSubmit(dataPrinter.controllable_printer_id);
        }

        else if(showConnectedPrinterUltimaker){
            if (dataPrinter.controllable_printer_id) {
                const identifier = dataPrinter.controllable_printer_id || '';
                const password = dataPrinter.controllable_printer_password || '';
                dataPrinter.controllable_printer_credentials = `${identifier}:${password}`;
            } else {
                dataPrinter.controllable_printer_credentials = null;
            }

            delete dataPrinter.controllable_printer_password;
        }

        setSelectedControllablePrinter(null);
        setRightPartIsOpen(false);
        await patchPrinters(dataPrinter)
            .then(() => successCallback('The printer has been updated.'))
            .catch(() => errorCallback('Something went wrong.'));
    }

    const setControllablePrinterValueForSubmit = (controllablePrinterId) => {
        // Set controllable printer value if it has changed
        if(selectedControllablePrinter !== null) {
            // Case no selected controllable printer
            if(selectedControllablePrinter === 0) {
                controllablePrinterId = null;
            } else {
                // Case new selected controllable printer
                controllablePrinterId = selectedControllablePrinter.id_manufacturer
            }
        } else if(printer.controllable_printer_id !== undefined){
            if(printer.controllable_printer_id === 0) {
                controllablePrinterId = null;
            } else {
                // Get the controllable printer original value if it has not changed
                controllablePrinterId = printer.controllable_printer_id;
            }
        } else {
            controllablePrinterId = null;
        }
        return controllablePrinterId;
    }

    const handleControllablePrinterChange = (e) => {
        let controllablePrinter = JSON.parse(e.target.value);
        // If controllable printer has been selected, handle form fields
        if(controllablePrinter !== 0) {
            // Set form values from controllable printer data
            if(controllablePrinter.printer_name !== undefined) {
                setValue('name', controllablePrinter.printer_name);
            }
            if(controllablePrinter.printer_series !== undefined) {
                setValue('serial_number', controllablePrinter.id_manufacturer);
            }
            if(controllablePrinter.brand !== undefined) {
                for(let i = 0; i < brands.length; i++) {
                    if(brands[i].printer_brand_data && brands[i].printer_brand_data.code === controllablePrinter.brand.code) {
                        setValue('brand', brands[i].id);
                        break;
                    }
                }
            }
            if(controllablePrinter.printer_type !== undefined) {
                let modelValue = null;
                // Check if model already exists in models list
                for(let i = 0; i < models.length; i++) {
                    if(models[i].name === controllablePrinter.printer_type) {
                        modelValue = models[i];
                        break;
                    }
                }
                // Case model does not exist : create new model
                if(modelValue === null) {
                    const newModel = {name: controllablePrinter.printer_type, newModel: true};
                    models.push(newModel);
                    modelValue = newModel;
                }
                setSelectedControllablePrinter(controllablePrinter);
                setValue('model', JSON.stringify({id: modelValue.id, name: modelValue.name, newModel: modelValue.newModel}));
            }
        } else {
            // Case no controllable printer selected
            setSelectedControllablePrinter(0);
        }
    }

    function handleTechnologyChange(e){
        let technology = JSON.parse(e.target.value);

        setSelectedTechnology(technology);
        setHasDiameter(technology.has_diameter);

        return e.target.value;
    }

    /* Check if controllable printer si already connected to an existing printer */
    const isControllablePrinterAlreadyConnected = (printerInList) => {
        for(let i = 0; i < printers.data.length; i++) {
            if (printers.data[i].controllable_printer_id === printerInList.id_manufacturer &&
                (printer.controllable_printer_id === undefined ||
                    printers.data[i].controllable_printer_id !== printer.controllable_printer_id)
            ) {
                return true;
            }
        }
        return false;
    }

    const setControllablePrinterDefaultValue = () => {
        if(!printer.controllable_printer_id) {
            // Case : no controllable printer for this printer (0 = no connected printer)
            return 0;
        } else {
            // Case : existing controllable printer, find corresponding controllable printer data
            if(controllablePrinters.data && controllablePrinters.data !== {}) {
                for(let [key, printerBrand] of Object.entries(controllablePrinters.data)) {
                    if(printerBrand.data) {
                        for(let controllablePrinter of printerBrand.data) {
                            if(controllablePrinter.id_manufacturer === printer.controllable_printer_id) {
                                return JSON.stringify(controllablePrinter);
                            }
                        }
                    }
                }
            }
            // Case : controllable printer not found in controllable printers list (ex: API credentials expired)
            return 0;
        }
    }

    const getControllablePrintersFlatList = () => {
        let controllablePrintersList = [];
        if(controllablePrinters.data && controllablePrinters.data !== {}) {
            for(let [key, printerBrand] of Object.entries(controllablePrinters.data)) {
                if(printerBrand.data) {
                    for(let controllablePrinter of printerBrand.data) {
                       controllablePrintersList.push(controllablePrinter);
                    }
                }
            }
        }
        return controllablePrintersList;
    }

    const manageShowConnectedPrinter = (value) => {
        const brandCode = brands.find(b => b.id === parseInt(value))?.printer_brand_data?.code;
        setShowConnectedPrinterMarkforged(parseInt(brandCode) === PRINTER_BRANDS.MARKFORGED.code);
        setShowConnectedPrinterUltimaker(parseInt(brandCode) === PRINTER_BRANDS.ULTIMAKER.code);
    };

    const [controllablePrinterId, controllablePrinterPassword] = printer.controllable_printer_credentials ?
        printer.controllable_printer_credentials.split(':') : [null, null];

    return (
        <span>
            <button onClick={() => setRightPartIsOpen(!rightPartIsOpen)}><i className="fa fa-pencil-alt"/></button>
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>

                <div id="right-part__container" className="right-part__container">
                    <div id="main__add-printer">

                        <div className="block">
                            <h2>Update a printer</h2>
                            <form onSubmit={handleSubmit(onSubmit)} id="add-printer__form">

                                <Controller
                                    control={control}
                                    name="id"
                                    defaultValue={printer.id}
                                    render={({field, value}) =>
                                        <input type="hidden" {...field} />}
                                />

                                <label htmlFor="f-brand">Printer brand</label>
                                <Controller
                                    control={control}
                                    name="brand"
                                    defaultValue={printer.brand.id}
                                    render={({field}) => (
                                        <select id="f-brand" {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    console.log(e.target.value);
                                                    manageShowConnectedPrinter(e.target.value);
                                                }}
                                        >
                                            {brands.map((brand) => (
                                                <option key={brand.id}
                                                        value={brand.id}>{brand.name}</option>
                                            ))}
                                        </select>
                                    )}
                                />

                                {
                                    showConnectedPrinterMarkforged &&
                                    <>
                                        <label htmlFor="f-brand">Connected printer</label>
                                        {/*Controllers are used to pass form data to updated state*/}
                                        <Controller
                                            control={control}
                                            name="controllable_printer"
                                            rules={{required: false}}
                                            defaultValue={setControllablePrinterDefaultValue()}
                                            render={({field}) => (
                                                <select id="f-controllable-printer" {...field}
                                                        onChange={(e) => field.onChange(handleControllablePrinterChange(e))}>
                                                    <option value={0}>No connected printer</option>
                                                    {getControllablePrintersFlatList().map((printer) => (
                                                        <option key={printer.brand + '-' + printer.id_manufacturer}
                                                                value={JSON.stringify(printer)}
                                                                disabled={isControllablePrinterAlreadyConnected(printer)}>
                                                            {printer.printer_name + ' - ' + printer.printer_type}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {isControllablePrinterError &&
                                            <p className="printer-controllable-printer-error"><i className='fa fa-exclamation-triangle'/> Could not find connected printer linked to this printer</p>
                                        }
                                    </>
                                }

                                {
                                    showConnectedPrinterUltimaker &&
                                    <>
                                        <hr/>
                                        <label htmlFor="f-controllable-printer-ip">Connected printer IP</label>
                                        {/* TODO Manage non-Ultimaker IP cases */}
                                        <Controller
                                            control={control}
                                            name="controllable_printer_ip"
                                            defaultValue={printer?.controllable_printer_ip?.replace('/api/v1', '')}
                                            render={({field, value}) =>
                                                <input type="text" id="f-controllable-printer-ip" {...field} />}
                                        />

                                        <label htmlFor="f-controllable-printer-id">Connected printer identifier</label>
                                        <Controller
                                            control={control}
                                            name="controllable_printer_id"
                                            defaultValue={controllablePrinterId}
                                            render={({field, value}) =>
                                                <input type="text" id="f-controllable-printer-id" {...field} />}
                                        />

                                        <label htmlFor="f-controllable-printer-password">Connected printer password</label>
                                        <Controller
                                            control={control}
                                            name="controllable_printer_password"
                                            defaultValue={controllablePrinterPassword}
                                            render={({field, value}) =>
                                                <input type="text" id="f-controllable-printer-password" {...field} />}
                                        />
                                        <hr/>
                                    </>
                                }

                                <label htmlFor="f-model">Model</label>
                                <Controller
                                    control={control}
                                    name="model"
                                    defaultValue={JSON.stringify({id: printer.model.id, name: printer.model.name})}
                                    render={({field}) => (
                                        <select id="f-model" {...field}>
                                            {models.map((model) => (
                                                <option key={model.name}
                                                        value={JSON.stringify({id: model.id, name: model.name, newModel: model.newModel})}>{model.name}</option>
                                            ))}
                                        </select>
                                    )}
                                />

                                <label htmlFor="f-serial-number">Serial number</label>
                                <Controller
                                    control={control}
                                    name="serial_number"
                                    defaultValue={printer.serial_number}
                                    rules={{required: true}}
                                    render={({field, value}) =>
                                        <input type="text" id="f-serial-number"
                                               {...field} />}
                                />

                                <label htmlFor="f-name">Name</label>
                                <Controller
                                    control={control}
                                    name="name"
                                    defaultValue={printer.name}
                                    rules={{required: true}}
                                    render={({field, value}) =>
                                        <input type="text" id="f-name"
                                               {...field} />}
                                />
                                {/* Printable volume */}

                                <label htmlFor="f-name">Printable height (mm)</label>
                                <Controller
                                    control={control}
                                    name="printableHeight"
                                    rules={{required: true}}
                                    defaultValue={printer.printable_height}
                                    render={({field}) =>
                                        <input type="number" id="f-printableHeight"
                                               placeholder="Printable height" {...field} />}
                                />
                                <label htmlFor="f-name">Printable length (mm)</label>
                                <Controller
                                    control={control}
                                    name="printableLength"
                                    rules={{required: true}}
                                    defaultValue={printer.printable_length}
                                    render={({field}) =>
                                        <input type="number" id="f-printableLength"
                                               placeholder="Printable length" {...field} />}
                                />
                                <label htmlFor="f-name">Printable width (mm)</label>
                                <Controller
                                    control={control}
                                    name="printableWidth"
                                    rules={{required: true}}
                                    defaultValue={printer.printable_width}
                                    render={({field}) =>
                                        <input type="number" id="f-printableWidth"
                                               placeholder="Printable width" {...field} />}
                                />

                                <label htmlFor="f-technology">Technology</label>
                                <Controller
                                    control={control}
                                    name="technology"
                                    defaultValue={JSON.stringify({
                                        id: printer.technology.id,
                                        has_diameter: printer.technology.has_diameter,
                                        diameters: printer.technology.diameters
                                    })}
                                    render={({field}) => (
                                        <select id="f-technology-id" {...field}
                                                onChange={(e) => field.onChange(handleTechnologyChange(e))}>
                                            {technologies.map((technology) => (
                                                <option key={technology.id}
                                                        value={JSON.stringify({
                                                            id: technology.id,
                                                            has_diameter: technology.has_diameter,
                                                            diameters: technology.diameters
                                                        })}
                                                        selected={printer.technology.id === technology.id}>{technology.name}</option>
                                            ))}
                                        </select>
                                    )}
                                />

                                {
                                    hasDiameter &&
                                    <div id="diameter-zone">
                                        <label htmlFor="f-diameter">Filament diameter</label>
                                        <Controller
                                            control={control}
                                            name="diameter"
                                            defaultValue={printer.diameter}
                                            render={({field}) => (
                                                <select id="f-diameter" {...field}>
                                                    {selectedTechnology.diameters.map((diameter, key) => (
                                                        <option key={key} value={key}
                                                                selected={printer.diameter === key}>{diameter} mm</option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                    </div>
                                }

                                <div id="dual-extruder-zone">
                                    <Controller
                                        control={control}
                                        name="extruder"
                                        defaultValue={printer.extruders_number > 1}
                                        render={({field}) =>
                                            <input type="checkbox" id="chk-dual-extruder"
                                                   {...field} />}
                                    />
                                    <label htmlFor="chk-dual-extruder"><span className="chk"/>
                                        Printer bi-nozzle
                                    </label>
                                </div>

                                {/*Fiber*/}
                                <div id="is_fiber">
                                        <Controller
                                            control={control}
                                            name="is_fiber"
                                            defaultValue={printer.is_fiber}
                                            render={({field}) =>
                                                <input type="checkbox" id="chk-fiber"
                                                       {...field} />}
                                        />
                                        <label htmlFor="chk-fiber"><span className="chk"></span>
                                            Fiber available
                                        </label>
                                    </div>

                                <button className="btn-white"><i className="fa fa-check"/> Update</button>

                            </form>
                        </div>
                    </div>
                </div>
            </Drawer>
        </span>
    )
}

