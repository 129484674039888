import React, {useEffect, useRef, useState} from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Container,
    Input,
    InputAdornment,
    Drawer
} from '@material-ui/core';
import {Avatar} from "../common/Avatar";
import { useStyles } from "../../css/pic/pic.js";
import handddle_logo from "../../images/logos/logo_handddle_small";
import EditIcon from "../../images/icons/pic/editIcon";
import LineAdd from "../../images/icons/pic/LineAdd";
import LineMoreIcon from "../../images/icons/pic/LineMoreIcon";
import IconMoreDefault from "../../images/icons/pic/IconMoreDefault";
import clsx from 'clsx';
import {CardMain} from "./CardMain";
import {getCurrentUser} from "../CommonFunctions";
import {PicCard} from "./Pic.class";
import {patchPic, postPic} from "../../api/apiPic";
import {PicDetailsComponent} from "./PicDetailsComponent";
import { useBeforeunload } from 'react-beforeunload';
import {useRouteMatch} from "react-router-dom";


export const PicBuilderComponent = (props) => {
    const classes = useStyles();
    const currentUser = getCurrentUser();
    const [, refreshGlobalState] = useState();

    let {path} = useRouteMatch();
    const isTemplate = path.includes('template');

    const {picData: openPicData} = props;

    const [isSaving, setIsSaving] = useState(false);
    let shouldSave = useRef(true);
    const [lastSaveDate, setLastSaveDate] = useState();
    // printerData corresponds to mainPrinterData from slicer card
    const [printerData, setPrinterData] = useState();

    // Various information about the current PIC
    const [picData, setPicData] = useState({
        id: null,
        name: '',
        pic_cards: [
            new PicCard(PicCard.TYPE_UNDEFINED, 'Begin the process')
        ]
    });

    const hasDefinedCard = () => {
        let definedCards = picData.pic_cards.filter(card => card.type !== PicCard.TYPE_UNDEFINED);
        return definedCards.length > 0;
    };

    // Load data if a PIC is opened
    useEffect(() => {
        if(openPicData)
            setPicData(openPicData);
    }, []);

    // State used to share information between cards -- Could be done with Redux
    const [globalCardData, updateGlobalCardData] = useState({});

    const updatePic = async () => {
        if(!shouldSave.current)
            return;

        if(!hasDefinedCard() && picData.name === '')
            return;

        // PIC not created
        if(!picData.id){
            // Define if PIC is template or PIC
            picData.is_template = isTemplate;
            setIsSaving(true);
            shouldSave.current = false;
            postPic(picData)
                .then(response => {
                    setPicData({...picData, id: response.id});
                    setLastSaveDate(new Date());
                    setIsSaving(false);
                    shouldSave.current = true;
                    console.log('PIC created.');
                })
                .catch(e => {
                    console.log(e)
                    console.log('Error while creating PIC.');
                })

            return;
        }

        // PIC already created, update it
        console.log('Saving ' + picData.pic_cards.length + ' card(s) [' + new Date().toString() + ']');

        setIsSaving(true);
        shouldSave.current = false;
        const patchResponse = await patchPic(picData);
        setLastSaveDate(new Date());
        setIsSaving(false);
        shouldSave.current = true;
    };

    useBeforeunload((event) => {
        if(shouldSave.current)
            updatePic();

        return false;
    });

    // Start the interval to save the PIC automatically
    useEffect(() => {
        const interval = setInterval(() => {updatePic()}, 10000);
        
        return () => {
            clearInterval(interval);
        };
    }, [picData, picData.pic_cards]);


    // Right menu management
    const [isRightMenuOpen, setIsRightMenuOpen] = useState(true);

    const MENU_STATES = {
        OVERVIEW: {id: 0, name: 'Overview'},
        EXPLOITATION: {id: 1, name: 'Exploitation'},
        HELP: {id: 2, name: 'Help'},
        HISTORY: {id: 3, name: 'History'}
    };
    const [sideMenuState, setSideMenuState] = useState(MENU_STATES['OVERVIEW']);

    const handleClickAddNextCard = (newCardType) => {
        if (newCardType !== null && newCardType !== PicCard.TYPE_UNDEFINED)
            setPicData({...picData, pic_cards: [
                ...picData.pic_cards, new PicCard(newCardType, PicCard.CARD_FIELDS[newCardType].name)
            ]});
        else
            setPicData({...picData, pic_cards: [
                ...picData.pic_cards, new PicCard(PicCard.TYPE_UNDEFINED, 'Next step')
            ]});
    }

    const handleClickAddCardBetween = (index) => {
        picData.pic_cards.splice(index, 0, new PicCard(PicCard.TYPE_UNDEFINED, 'Next step'));
        refreshGlobalState({});
    }

    const handleClickDeleteCard = (index) => {
        picData.pic_cards.splice(index, 1);
        refreshGlobalState({});
    }

    const handleContinueCard = (picCard) => {
        if(picData.pic_cards[picData.pic_cards.length-1].card.id === picCard.card.id)
            handleClickAddNextCard(PicCard.DISPLAY_DATA[picCard.type].next_card);
    }

    /* If STL file uploaded, name pic with file name if pic does not already have a name */
    const updateUntitledPicNameFromStl = (fileName) => {
        if(picData.name === undefined || picData.name === null || picData.name === '') {
            picData.name = fileName;
            setPicData({...picData, name: fileName});
        }
    }

    return (
        <div className="main__pic-main">
            <AppBar position="fixed" className="menu-bar">
                <Toolbar>
                    <IconButton className={classes.root} disableRipple edge="start" aria-label="return-app" onClick={async () => {
                        window.location = '/manager'
                    }}
                    >
                        <span className={classes.icon_return_app}><i className="fa fa-angle-left"/></span>
                    </IconButton>
                    <div className="nav-bar-end">
                        <div className="saving">
                            {
                                isSaving ? 'Saving PIC...'
                                    : lastSaveDate ? 'PIC saved at ' +
                                    ('0' + lastSaveDate.getHours()).slice(-2) + ":" +
                                        ('0' + lastSaveDate.getMinutes()).slice(-2) + ":" +
                                            ('0' + lastSaveDate.getSeconds()).slice(-2)
                                    : null
                            }
                        </div>
                        <button className="new-button" onClick={() => {window.location.href = '/builder'}}>
                            New
                        </button>
                        <Avatar user={currentUser}/>
                    </div>
                </Toolbar>
            </AppBar>

            {/* Main container */}
            <Container className={clsx(classes.page_content, { [classes.menuOpen]: isRightMenuOpen && window.innerWidth < 1600})}>

                {/* Name of the new PIC */}
                <form className="edit-pic-name__form">
                    <Input
                        className={classes.f_name}
                        fullWidth={true}
                        required={true}
                        value={picData.name}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") e.preventDefault();
                        }}
                        onChange={(e) => {
                            setPicData({...picData, name: e.target.value});
                        }}
                        inputProps={{style: {background: 'transparent'}, classes: {textareas: classes.input_placeholder}}}
                        placeholder={isTemplate ? "Name your template" : "Name your PIC"} startAdornment={
                        <InputAdornment position="start">
                            <EditIcon/>
                        </InputAdornment>
                    }
                    />
                </form>

                {/* Cards list */}
                <div className="card-container">
                    {picData.pic_cards.map((picCard, index) => (
                        <React.Fragment key={index}>
                            {index >= 1 &&
                                <div className="add-card-line-div" onClick={() => handleClickAddCardBetween(index, picCard)}>
                                    <LineMoreIcon className="add-card-line-icone"/>
                                    <IconMoreDefault className="add-card-button-icone"/>
                                </div>
                            }
                            <CardMain
                                picCard={picCard}
                                index={index}
                                refreshGlobalState={refreshGlobalState}
                                handleClickDeleteCard={handleClickDeleteCard}
                                globalCardData={globalCardData}
                                isScheduled={picData.tasks && picData.tasks.length > 0}
                                canDelete={index === 0 && picData.pic_cards.length === 1}
                                handleContinueCard={handleContinueCard}
                                setPrinterData={setPrinterData}
                                updateUntitledPicNameFromStl={updateUntitledPicNameFromStl}
                            />
                        </React.Fragment>
                    ))}
                    <div className="add-card-line-div" onClick={() => handleClickAddNextCard(PicCard.TYPE_UNDEFINED)}>
                        <LineAdd className="add-card-button-line-icone" />
                    </div>
                </div>
            </Container>

            {/* Right menu */}
            {/*<Drawer variant="permanent" anchor="right" className={classes.menu_side_bar} classes={{paper: clsx(classes.menu_side_bar_content, { [classes.menuDrawerShift]: isRightMenuOpen})}}>*/}
            {/*    <Button disableRipple onClick={() => handleMenuButtonClick(MENU_STATES['OVERVIEW'])} className={classes.menu_side_bar_icone_button}>*/}
            {/*        <BurgerMenuIcon className={sideMenuState.id === MENU_STATES['OVERVIEW'].id && isRightMenuOpen ? 'menu-side-bar-icone active' : 'menu-side-bar-icone'}/>*/}
            {/*    </Button>*/}
            {/*    <Button disableRipple onClick={() => handleMenuButtonClick(MENU_STATES['EXPLOITATION'])} className={classes.menu_side_bar_icone_button}>*/}
            {/*        <ExploitationMenuIcon className={sideMenuState.id === MENU_STATES['EXPLOITATION'].id && isRightMenuOpen ? 'menu-side-bar-icone active' : 'menu-side-bar-icone'}/>*/}
            {/*    </Button>*/}
            {/*    <Button disableRipple onClick={() => handleMenuButtonClick(MENU_STATES['HELP'])} className={classes.menu_side_bar_icone_button}>*/}
            {/*        <HelpMenuIcon className={sideMenuState.id === MENU_STATES['HELP'].id && isRightMenuOpen ? 'menu-side-bar-icone active' : 'menu-side-bar-icone'}/>*/}
            {/*    </Button>*/}
            {/*    <Button disableRipple onClick={() => handleMenuButtonClick(MENU_STATES['HISTORY'])} className={classes.menu_side_bar_icone_button}>*/}
            {/*        <HistoryMenuIcon className={sideMenuState.id === MENU_STATES['HISTORY'].id && isRightMenuOpen ? 'menu-side-bar-icone active' : 'menu-side-bar-icone'}/>*/}
            {/*    </Button>*/}
            {/*</Drawer>*/}
            <Drawer variant="persistent" anchor="right" open={isRightMenuOpen} className={classes.side_bar} classes={{paper: classes.side_bar_content}}>
                {/*<div className="side-bar-header">*/}
                {/*    <h1 className="side-bar-header-title">{sideMenuState.name}</h1>*/}
                {/*    <CloseIcon className="side-bar-header-close-icone" onClick={() => setIsRightMenuOpen(false)}/>*/}
                {/*</div>*/}
                <div className="side-bar-content">
                    {sideMenuState.id === MENU_STATES['OVERVIEW'].id &&
                        <PicDetailsComponent
                            picData={picData}
                            globalCardData={globalCardData}
                            printerData={printerData}
                        />
                    }
                </div>
            </Drawer>
        </div>
    )
}

