import React, {useEffect, useState, useMemo} from "react";
import {getCurrentUser, getFormattedResourceName} from "../../../CommonFunctions";
import {ResourceMaterialBar} from "./ResourceMaterialBar";
import {ResourceMenu} from "./ResourceMenu";
import {
    formatDataBySystemCode, getFormattedHumidityValue,
    getFormattedTemperatureValue,
    getSingleInfluxdbDataBySystemCode
} from "../../../../api/apiInfluxdb";
import {useHistory} from "react-router-dom";
import {getReference, getResourcesRemainingQuantityByReference} from "../../../../services/gantt/ResourceChoiceService";
import {useGetData} from "../../../../api/useFetch";
import {MaterialIcon} from "../../../common/icons/MaterialIcon";

export const ResourceRow = (props) => {
    const {printer, tasksSelected, setTasksSelected, setAlert, setIsWorkloadDialogOpen, setPrinterTaskWorkload,
        firstDisplayedDate, externalPrintTasks, reloadCalendar} = props;
    const [temperature, setTemperature] = useState(0);
    const [humidity, setHumidity] = useState(0);

    let user = getCurrentUser();
    const resources = useGetData(`resourcesData`, 'resource_items',
      {organization_group: user.organization.organization_group.id,archived: false}
    );

    // Store the system code for the printer concerned by this row;
    const systemCode = printer.farm_cell?.system_code;

    //fetch data from couchbase and get the temperature and humidity for the printer inside the cellFarm
    const fetchDataFunc = () => {
        getSingleInfluxdbDataBySystemCode([systemCode], ['temperature', 'humidity'])
        .then(data => {
            const formattedData = formatDataBySystemCode(data);
            setTemperature(formattedData[systemCode]?.temperature ? getFormattedTemperatureValue(formattedData[systemCode]?.temperature) : null);
            setHumidity(formattedData[systemCode]?.humidity ? getFormattedHumidityValue(formattedData[systemCode]?.humidity) : null);
        }).catch(error => {console.log(error);});
    };

    // Every 30 sec, we update the data available from the data server
    useEffect(()=> {
        const interval = setInterval(() => {
            // We call fetchDataFunc if the printer has a system Code meaning it is in a smart Farm
            if(systemCode !== undefined)
                fetchDataFunc();
        }, 30000);
        return () => {
            clearInterval(interval);
        };
    },[]);

    useEffect(() => {
       if(systemCode !== undefined)
           fetchDataFunc();
    }, []);

    const resourceClassNames = useMemo(() => {
        let classNames = "gc-resource-row__cell gc-resource-row__cell--printer gc-resource-printer";
        if (!printer.farm_cell) {
            classNames += " gc-resource-printer--outfarm";
        }
        return classNames;
    }, []);

    const getMissingResources = () => {
        if(resources.isLoading || resources.isError) return;

        const remainingQuantities = getResourcesRemainingQuantityByReference(resources.data);
        let neededTasksQuantities = {};

        for(const task of printer.tasks){
            if(task.date <= Date.now() / 1000 || !task.material1) continue;

            const reference = getReference(task.material1, task.brand1, task.color1);
            if(!(reference in neededTasksQuantities)) neededTasksQuantities[reference] = 0;

            neededTasksQuantities[reference] += task.quantity1;
        }

        let components = [];

        for(const [reference, neededQuantity] of Object.entries(neededTasksQuantities)){
            if(!(reference in remainingQuantities) || remainingQuantities[reference] < neededQuantity){
                const referenceObj = JSON.parse(reference);
                components.push(
                  <div className="warning" key={referenceObj.material + referenceObj.brand + referenceObj.color}>
                      <i className='fa fa-exclamation-triangle'/>
                      {referenceObj.material + ' - ' + referenceObj.brand + ' (' + referenceObj.color + ')'}
                  </div>
                );
            }
        }

        return components;
    };

    let history = useHistory();

    return (
        <tr className="gc-resources-table__row gc-resource-row">
            <td className="gc-resource-row__cell gc-resource-row__cell--farm gc-resource-farm">
            {printer.farm_cell ? (
                <div>
                    T: {temperature > 0 ? temperature : '-'} °C
                    <br />
                    H: {humidity > 0 ? humidity : '-'} %
                    <br />
                    <button onClick={() => history.push('/monitoring/' + printer.farm_cell.id)}>More</button>
                </div>
            ):(
                <div style={{textAlign:'center'}}>
                Not in <br/> Smartfarm
                </div> 
            )}
            </td>

            <td className="gc-resource-row__cell gc-resource-row__cell--separator gc-resource-separator">
                <div className="gc-resource-separator__bar"/>
            </td>
            <td className={resourceClassNames}>
                <h3 className="gc-resource-printer__name">{printer.name}</h3>
                <div className="gc-resource-printer__info">
                    {printer.technology.name}
                </div>
                <div className="gc-resource-printer__materials gc-materials">
                    {getMissingResources()}
                </div>
            </td>

            <ResourceMenu
                printer={printer}
                tasksSelected={tasksSelected}
                setTasksSelected={setTasksSelected}
                setAlert={setAlert}
                setIsWorkloadDialogOpen={setIsWorkloadDialogOpen} 
                setPrinterTaskWorkload={setPrinterTaskWorkload}
                firstDisplayedDate={firstDisplayedDate}
                externalPrintTasks={externalPrintTasks}
                reloadCalendar={reloadCalendar}
            />
        </tr>
    );
};
