import FileUploadBlack from "../../images/icons/pic/file_upload_black_24dp";
import React from "react";

export class PicCard{

    static TYPE_UNDEFINED = 10;
    static TYPE_BUILD_PLATFORM = 0;
    static TYPE_SLICER_CONNECTION = 1;
    static TYPE_MACHINE_JOB = 2;
    static TYPE_POST_TREATMENT = 3;
    static TYPE_SMART_FARM = 4;


    static CARD_FIELDS = {
        [this.TYPE_SLICER_CONNECTION]: {
            type: 'CardSlicer',
            printer_brand: null,
            printers: [],
            main_printer: null,
            material1: null,
            material2: null,
            brand1: null,
            brand2: null,
            color1: null,
            color2: null,
            duration: 0,
            quantity1: 0,
            quantity2: 0,
            build_id: null
        },
        [this.TYPE_MACHINE_JOB]: {
            type: 'CardMachineJob',
            machine_job_time_slots: []
        },
        [this.TYPE_BUILD_PLATFORM]: {
            type: 'CardPlatform',
            card_platform_pieces: [],
            card_platform_attachments: []
        },
        [this.TYPE_POST_TREATMENT]: {
            type: 'CardPostTreatment',
            method: '',
            duration: 0,
            description: ''
        },
        [this.TYPE_SMART_FARM]: {
            type: 'CardSmartFarm',
            desired_temperature: null,
            desired_humidity: null
        }
    };


    static DISPLAY_DATA = {

        [this.TYPE_BUILD_PLATFORM]: {
            type: this.TYPE_BUILD_PLATFORM,
            name: 'Build platform',
            icon: <FileUploadBlack className="card-item-icone"/>,
            description: 'Upload your 3D files',
            step: 1,
            next_card: null,
            available: true
        },

        [this.TYPE_SLICER_CONNECTION]: {
            type: this.TYPE_SLICER_CONNECTION,
            name: 'Slicer information',
            icon: <FileUploadBlack className="card-item-icone"/>,
            description: 'Setup your slicer information',
            step: 1,
            next_card: this.TYPE_MACHINE_JOB,
            available: true
        },

        [this.TYPE_MACHINE_JOB]: {
            type: this.TYPE_MACHINE_JOB,
            name: 'Machine job',
            icon: <FileUploadBlack className="card-item-icone"/>,
            description: 'Add extra times',
            step: 2,
            next_card: null,
            available: true
        },

        [this.TYPE_POST_TREATMENT]: {
            type: this.TYPE_POST_TREATMENT,
            name: 'Post treatment',
            icon: <FileUploadBlack className="card-item-icone"/>,
            description: 'Add post treatment operations',
            step: 3,
            next_card: null,
            available: true
        },

        // [this.TYPE_SMART_FARM]: {
        //     type: this.TYPE_SMART_FARM,
        //     name: 'Smart Farm',
        //     icon: <FileUploadBlack className="card-item-icone"/>,
        //     description: 'Set environment parameters',
        //     step: 1,
        //     next_card: null,
        //     available: true
        // },

        [this.TYPE_UNDEFINED]: {
            type: this.TYPE_UNDEFINED,
            name: 'New card',
            available: false
        }

    };


    constructor(type, card) {
        // Ranking is defined "on-the-fly" just before the save request

        this.type = type;
        this.is_complete = true; // TODO Manage this point

        if(type === PicCard.TYPE_UNDEFINED)
            this.card = {};
        else
            // When opening an existing PIC with cards with data
            this.card = Object.assign({}, PicCard.CARD_FIELDS[type], card);
    }

}
